<template lang="pug">
#remove-battery.video-step-dialog.is-fullscreen
  .modal-text
    icon.cd-icon(v-if="iconPath", :data="iconPath", original="true")
    h1 Please remove the batteries from the spinner device. This is very important to ensure the safety of your sample during transit. Failure to do so may result in the shipping carrier to remove your package from circulation and cause inconvenience for you.
    h2 How to Remove Batteries
    ol
      li Pull off the foam piece from bottom of the spinner device
      li Remove the plastic battery tab
      li Remove batteries
    h1 Restoring the battery tab or the foam cover back into place is not necessary.
  .modal-footer
    button.button.action-btn(@click="performAction") Continue
</template>

<script>
export default {
  props: {
    onAction: { type: Function, required: true },
  },

  data() {
    return {
      iconPath: null,
    }
  },

  computed: {},

  watch: {},

  created() {
    this.iconPath = require('@icon/battery.svg')
  },

  methods: {
    performAction,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */

function performAction() {
  this.$modal.open('BatteryConfirmation', {
    onAction: this.onAction,
  })
}
</script>
